import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

const HomeMenu = [
    {
        name: 'Inventarios',
        routerPath: '/'
    }, {
        name: 'Ventas',
        routerPath: '#'
    }, {
        name: 'Promotoría',
        routerPath: '#'
    }, {
        name: 'Forecast',
        routerPath: '#'
    }, {
        name: 'Nivel de Servicio',
        routerPath: '#'
    }
];

const AboutMenu = [
    {
        name: 'Canal Moderno',
        routerPath: '#'
    }, {
        name: 'Autoservicios',
        routerPath: '#'
    }, {
        name: 'Farmacias',
        routerPath: '#'
    }, {
        name: 'Clubes de Precio',
        routerPath: '#'
    }, {
        name: 'Conveniencia',
        routerPath: '#'
    }, {
        name: 'Top 5 Nacional',
        routerPath: '#'
    }
];

const ServiceMenu = [
    {
        name: 'PyMe Moderno',
        routerPath: '#'
    }, {
        name: 'PyMe Autoservicios',
        routerPath: '/service-two'
    }
];

const TeamMenu = [
    {
        name: 'Desarrollo e incripción a los portales',
        routerPath: '/team-member'
    }, {
        name: 'Bussines Intelligence Básico',
        routerPath: '/team-details'
    }
];

const PortfolioMenu = [
    {
        name: 'Consultoría',
        routerPath: '/portfolio-3'
    }, {
        name: 'Reporting',
        routerPath: '/portfolio-2'
    }, {
        name: 'Data analysis',
        routerPath: '/portfolio-masonry'
    }, {
        name: 'Forecasting',
        routerPath: '/portfolio-single'
    }
];

const BlogMenu = [
    {
        name: 'Excel Avanzado',
        routerPath: '#'
    }, {
        name: 'Power Bi',
        routerPath: '#'
    }, {
        name: 'Python Data Science',
        routerPath: '#'    
    }, {
        name: 'IA con TensorFlow',
        routerPath: '#'    
    }
];

const Miscellaneous = [
    {
        name: 'Testimonials',
        routerPath: '/testimonial'
    }, {
        name: 'Our Pricing',
        routerPath: '/price'
    }, {
        name: 'FAQ',
        routerPath: '/faq'
    }, {
        name: '404 Error',
        routerPath: '/error'
    }, 
];

const ThemeMainMenu = () => {

    return (
        <Fragment>
            <ul className="navbar-nav">
                <li className="d-block d-lg-none">
                    <div className="logo">
                        <Link to="/"><img src="images/logo/logo_01.png" alt="" width={130}/></Link>
                    </div>
                </li>
                <li className="nav-item active dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button">Inicio</a>
                    
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button">Planes API</a>
                    <ul className="dropdown-menu">
                        <li className="dropdown-submenu dropdown">
                            <a className="dropdown-item dropdown-toggle" href="#">
                                <span>Membresía Básica</span>
                            </a>
                            <ul className="dropdown-menu">
                                {AboutMenu.map((val, i) => (
                                    <li key={i}>
                                        <Link to={val.routerPath} className="dropdown-item">
                                            <span>{val.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="dropdown-submenu dropdown">
                            <a className="dropdown-item dropdown-toggle" href="#">
                                <span>Membresía PyMe</span>
                            </a>
                            <ul className="dropdown-menu">
                                {ServiceMenu.map((val, i) => (
                                    <li key={i}>
                                        <Link to={val.routerPath} className="dropdown-item">
                                            <span>{val.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="dropdown-submenu dropdown">
                            <a className="dropdown-item dropdown-toggle" href="#">
                                <span>Nuevos proveedores</span>
                            </a>
                            <ul className="dropdown-menu">
                                {TeamMenu.map((val, i) => (
                                    <li key={i}>
                                        <Link to={val.routerPath} className="dropdown-item">
                                            <span>{val.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>

                        
                        
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#">Servicios</a>
                    <ul className="dropdown-menu">
                        {PortfolioMenu.map((val, i) => (
                            <li key={i}>
                                <Link to={val.routerPath} className="dropdown-item">
                                    <span>{val.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#">Capacitación</a>
                    <ul className="dropdown-menu">
                        {BlogMenu.map((val, i) => (
                            <li key={i}>
                                <Link to={val.routerPath} className="dropdown-item">
                                    <span>{val.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/contact" role="button">Contacto {" "}</Link>
                </li>
            </ul>
        </Fragment>
    )
}
export default ThemeMainMenu
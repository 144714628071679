import React, {Fragment} from 'react'

const ProcessContent = [
    {
        num: 1,
        className: 'numb tran3s',
        heading: 'Colaboración',
        desc: 'Retailers y proveedores, un solo equipo para planes de abasto y ventas en conjunto.',
        dataDelay: ''
    }, {
        num: 2,
        className: 'numb tran3s',
        heading: 'Planeación de la demanda',
        desc: 'Analisis e insights de ventas,inventarios y tendencias para la planeación anticipada y eficiente.',
        dataDelay: '50'
    }, {
        num: 3,
        className: 'numb tran3s',
        heading: 'Forecasting',
        desc: 'Contamos con modelos de pronóstico a nivel código tienda, asistidos con Inteligencia Artificial.',
        dataDelay: '100'
    }, {
        num: 4,
        className: 'numb tran3s',
        heading: 'Replenishment',
        desc: 'Garantía del abasto en el punto de venta, con el seguimiento a la logística operativa y la distribución.',
        dataDelay: '100'
    }
]

const FancyFeatureNineteen = () => {
    return (
        <Fragment>
            <div className="block-style-thirteen" data-aos="fade-right">
                <div className="title-style-three pb-15">
                    <div className="sc-title">Modelos de colaboración</div>
                    <h2 className="main-title">¿Que es el modelo <span>CPFR</span> y como funciona?</h2>
                </div>
                {/* /.title-style-three */}
                <ul className="style-none list-item">
                    {ProcessContent.map((val, i) => (
                        <li key={i} data-aos="fade-up" data-aos-delay={val.dataDelay}>
                            <div className={val.className}>{val.num}</div>
                            <h6>{val.heading}</h6>
                            <span>{val.desc}</span>
                        </li>
                    ))}
                </ul>
            </div>
            {/* /.block-style-thirteen */}
        </Fragment>
    )
}

export default FancyFeatureNineteen